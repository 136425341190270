<template>
  <base-card>
    src\components\section\reviews\type\ReviewsItemAboutInstitutions.vue
    тут нужно будет продумать дизайн.
  </base-card>
</template>

<script>
import BaseCard from '@/components/base/card/BaseCard'
export default {
  name: 'ReviewsItemAboutInstitutions',
  components: { BaseCard },
  props: {
    value: {
      type: Object,
      default: () => {
        return {}
      }
    }
  }
}
</script>
